.testimonial-one img {
  display: unset !important;
  width: 90%;
}
.coinDetails-datepiker .picker__container {
  position: relative !important;
}
.wallet-card {
  margin: 0 auto;
  width: 95% !important;
}
.dataTables_wrapper .dataTables_paginate span .paginate_button {
  display: inline-block;
}
.sidebar-right
  .tab-content
  .tab-pane
  .admin-settings
  input[type="radio"]:checked
  + label::after {
  background-color: rgba(1, 1, 1, 0) !important;
}
/* ////////////////////////fixed */
.element-right-round button#dropdown-button-drop-dwon {
  border-radius: 0 0.75rem 0.75rem 0;
}
._i-hover:hover {
  left: 0 !important;
}
@media screen and (min-width: 695px) {
  .d-unset {
    display: unset !important;
  }
}
.d-unset {
  display: block;
}
button#check-minutes {
  border-radius: 0 0.75rem 0.75rem 0;
}
.fc-toolbar-chunk {
  display: flex;
}
@media screen and (max-width: 335px) {
  .fc-toolbar-chunk {
    display: block;
    text-align: center;
    line-height: 2.8;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin: 0 !important;
  }
}
/* Editor */
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

/* Map */
svg.svg-map {
  stroke: white !important;
}
.svg-map__location {
  fill: #eff2f4 !important;
}
.svg-map__location:focus,
.svg-map__location:hover {
  fill: black !important;
}

.p-meddle {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
/* color picker */

.chrome-picker {
  position: absolute;
  top: -310px;
}
.color-gradian-tixia .popover {
  top: -410px !important;
}
/* wizard */

/* date piker */
@media screen and (max-width: 590px) {
  .rangeDatePicker .calendar__item {
    display: block !important;
    width: 100%;
  }
  .rangeDatePicker .calendar__container {
    width: 100% !important;
  }
}

form#step-form-horizontal div:first-child > div:last-child {
  text-align: right;
  /* padding-top: 2rem; */
}

label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eef5f9;
  text-align: center;
  margin: auto;
}

.wizard-four-color {
  color: #222fb9;
  text-decoration: underline;
}

.redio-false {
  display: none;
}

span.mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

span.mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

/* Wizard end */

/* cursor-pointer */
.cursor-pointer {
  cursor: pointer;
}
/* form */
#multiselect button.btn.dropdown-toggle.btn-light {
  background: no-repeat;
  border: 1px solid #d7dae3;
  color: #3e4954;
  font-weight: 100;
}
/* slider */

.card-text {
  margin-bottom: 0;
}
#whiteSpace {
  white-space: normal;
}

#tp-btn::after {
  content: "";
}

.swal-footer {
  text-align: center !important;
  margin: 0 !important;
}

.ui .progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}
@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
ol.go2441762052 {
  text-align: center;
}

#order2 {
  order: 2;
}

/* Editor */

.summernote > :first-child {
  min-height: 250px;
  border: 1px solid #a9a9a9;
  border-color: #f0f1f5;
}
.rdw-image-modal {
  right: 0 !important;
  left: unset !important;
}
.rdw-editor-toolbar {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

/* Button */
button:focus,
a:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Chart */

.go2478912419 {
  text-align: center;
  display: block;
  margin-bottom: 2rem;
}

.picker-input__text,
.range-picker-input {
  width: 100% !important;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root {
  flex: 1 1 auto;
}

.timepicker-container input {
  background: #fff;
  border: 1px solid #f0f1f5;
  color: #b1b1b1;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  height: 41px;
  width: 100%;
  box-sizing: border-box;
}

.MuiFormControl-root.MuiTextField-root {
  display: block;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100%;
  height: 100%;
}

.dd .nestable-item-name {
  color: #fff;
  background: #363062;
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 13px;
  margin-bottom: -4px;
}

/* Picker */

.i-false:after {
  content: none !important;
}

rect#chart_ChartBorder {
  fill: transparent;
}

/* pagination */
.pagination {
  margin-bottom: 0 !important;
}
@import url("https://fonts.googleapis.com/css?family=Lato");
.rc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 80;
}
.rc-backdrop.invert {
  background: rgba(1, 1, 1, 0.7);
}
.range-picker-input {
  display: inline-flex;
  border: 1px solid #e4e4e4;
  width: 300px;
}
.range-picker-input * {
  box-sizing: border-box;
}
.range-picker-input__icon {
  display: inline-flex;
  align-items: center;
}
.range-picker-input__start,
.range-picker-input__end {
  display: inline-flex;
  flex: 1;
}
.range-picker-input__start .picker-input.range input,
.range-picker-input__end .picker-input.range input {
  width: 100%;
  border: none;
}
.picker-input {
  display: inline-block;
  position: relative;
}
.picker-input__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  display: inline-flex;
  align-items: center;
}
.picker-input__text {
  padding: 10px;
  border: 1px solid #e4e4e4;
  outline: none;
  font-size: 16.8px;
}
.picker-input__text:disabled {
  background: #e4e4e4;
}
.picker-input__clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}
.picker {
  display: inline-block;
}
.picker__container {
  position: absolute;
  z-index: 100;
}
.picker__container.portal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.picker__container__include-time {
  border: 1px solid #e4e4e4;
}
.picker__container__include-time .calendar__item,
.picker__container__include-time .time__container {
  border: none;
}
.picker__container__tab {
  margin: 10px 0;
}
.picker__container__tab button {
  padding: 5px 10px;
  outline: none;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 2px solid #e4e4e4;
}
.picker__container__tab button.active {
  color: #49599a;
  border-bottom: 2px solid #49599a;
}
.picker__container__tab button:first-child {
  border-right: none;
}
.picker__container__tab button svg {
  margin-right: 5px;
}
.time__container {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  padding: 15px;
  background: white;
  font-family: "Lato";
  font-size: 12px;
}
.time__container__div {
  margin: 0 10px;
}
.time__container__type {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.time-input {
  display: inline-block;
  width: 40px;
  overflow: hidden;
}
.time-input__up,
.time-input__down {
  border: 1px solid #e4e4e4;
}
.time-input__up button,
.time-input__down button {
  outline: none;
  width: 100%;
  cursor: pointer;
  border: none;
}
.time-input__text {
  width: 100%;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  box-sizing: border-box;
}
.time-input__text input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  font-size: 15px;
  padding: 5px;
  text-align: center;
  outline: none;
}

/* calender */

.react-calendar {
  border: none !important;
  font-family: "poppins", sans-serif;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: none !important;
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #dee2e6 !important;
  font-family: "poppins", sans-serif;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  font-family: "poppins", sans-serif;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  display: none;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  border-radius: 10px;
  color: black;
  color: #000;
  font-weight: 500;
  height: 46px;
  line-height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "poppins", sans-serif;
}
button.react-calendar__navigation__label {
  /* padding: 20px 15px; */
  border-radius: 1.75rem !important;
  font-size: 20px;
}
button.react-calendar__navigation__label:hover {
  color: white !important;
  background: #eeeeee !important;
}
button.react-calendar__navigation__label:hover > span {
  /* color: white !important; */
  /* background: #EEEEEE !important; */
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation__label:hover > span {
  /* background-color: #eeeeee !important; */
  /* color: white; */
  /* border-radius: 50%; */
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive,
.react-calendar__navigation button[disabled],
.react-calendar__navigation button[disabled] > span {
  background-color: #eeeeee !important;

  /* color: white; */
}
button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day,
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  position: relative;
  background: #eeeeee !important;
  box-shadow: none;
  /* color: white !important; */
  border-radius: 10px;
  overflow: unset !important;
  padding: 16px 5px 15px 5px;
  /* border-radius: 35px; */
  line-height: 1;
}

.react-calendar {
  border: none;
}

.calendar {
  display: inline-block;
  background: white;
  font-size: 12px;
}
.calendar *,
.calendar *:before,
.calendar *:after {
  box-sizing: border-box;
}
.calendar__container {
  width: 270px;
  font-family: "Roboto", sans-serif;
  display: none;
}
.calendar__list {
  display: table;
}
.calendar__item {
  display: table-cell;
  border: 1px solid #ececec;
}
.calendar__item:not(:first-child) {
  border-left: none !important;
}
.calendar--show {
  display: inline-block;
}
.calendar__head {
  position: relative;
  background: #7986cb;
  padding: 10px 6px;
}
.calendar__head--title {
  font-size: 1.3em;
  color: white;
  text-align: center;
  margin: 4px;
}
.calendar__head--button {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 20px;
}
.calendar__head--button svg {
  fill: white;
}
.calendar__head--prev,
.calendar__head--next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.calendar__head--prev {
  left: 0;
}
.calendar__head--next {
  right: 0;
}
.calendar__panel--show {
  display: block !important;
}
.calendar__panel--today {
  background: #aab6fe;
  padding: 5px;
  display: none;
}
.calendar__panel--today h2 {
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}
.calendar__body--table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-spacing: none;
  border-collapse: collapse;
}
.calendar__body--table th {
  height: 30px;
  vertical-align: middle;
  color: #212121;
}
.calendar__day {
  vertical-align: top;
  padding-top: 5px;
  height: 40px;
  cursor: pointer;
}
/* .calendar__day self:hover:not(&--disabled) {
   background: #aab6fe;
   cursor: pointer;
} */
.calendar__day--0 {
  color: red;
}
.calendar__day--6 {
  color: blue;
}
.calendar__day--today {
  background: #fff9c4;
}
.calendar__day--disabled {
  color: #ddd;
  cursor: initial;
}
.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
  background: #7986cb;
  color: #fff;
}
.calendar__day--start:hover,
.calendar__day--end:hover,
.calendar__day--selected:hover {
  background: #7986cb;
}
.calendar__day--range {
  background: #dce1ff;
}
.calendar__day--text {
  display: block;
  font-size: 10px;
}
.calendar__year,
.calendar__month {
  height: 55px;
  vertical-align: middle;
}
.calendar__year:hover,
.calendar__month:hover {
  background: #aab6fe;
  cursor: pointer;
}

/* Selector Design */
.css-1okebmr-indicatorSeparator {
  display: none;
}
.pagination-nav__circle{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #969BA0;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 5px;
  transition: .2s;
}
.pagination-nav__circle:last-child{
  margin-right: 0;
}
.pagination-nav__circle:hover{
  transition: .2s;
  border: 1px solid #363062;
  background-color: #363062;
  color: #fff;
}
@media screen and (max-width: 900px) {
  .pagination-nav__circle:hover{
    transition: .2s;
    border: 1px solid #969BA0;
    background-color: transparent;
    color: #969BA0;
  }
}
@media screen and (max-width: 800px) {
  .pagination-nav__circle:hover{
    transition: .2s;
    border: 1px solid #363062;
    background-color: #363062;
    color: #fff;
  }
}
.pagination-nav__circle--active{
  color: #fff;
  border: 1px solid #363062;
  background-color: #363062;
  cursor: default;
}
th{
  text-transform: initial !important;
}
.search-dropdown{
  z-index: 10;
  background-color: #fff;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  top: calc(100% - 9px);
  display: none;
  width: calc(100% - 30px);
  border-bottom: 1px solid #6a60b1;
  border-left: 1px solid #6a60b1;
  border-right: 1px solid #6a60b1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.search-dropdown div{
  cursor: pointer;
  margin-bottom: 3px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.search-dropdown div:hover{
  background-color: #f1f2f5;
}
.search-dropdown div:last-child{
  margin-bottom: 0;
}
.search-dropdown--active{
  display: block;
}
.last{
  border-bottom: none;
}
.custom-control-label{
  cursor: pointer;
}
@media screen and (max-width: 374px) {
  .pagination-nav__circle{
    width: 25px;
    height: 25px;
  }
}
.bg-f1f2f5{
  background-color: #f1f2f5 !important;
}
.leader{
  cursor: pointer;
}
.font-size-20{
  font-size: 20px;
  cursor: pointer;
}
.leader_cursor_default{
  cursor: default;
}
.close-icon{
  top: 0;
  right: 15px;
}
.footer{
  display: none;
}
.search-accaunt-close{
  top: 6px;
  left: calc(100% - 45px);
}
.react-datepicker-wrapper{
  width: auto !important;
}
.date-input{
  height: 25px !important;
}
.custom-date-data{
  top: 0;
  left: 100%;
   height: 21px;
  width: 70px;
  text-align: center;
  margin-left: -35px;
}
.custom-date-data__wrap{
 height: 22px;
}
.date-error{
  top: -15px;
}
.title-view-acc{
  font-size: 16px !important;
}
.leverage{
  padding: 3px;
  border-radius: 2px;
  background-color: #ebe9f4;
  font-size: 12px;
  color: #d2a30e;
}
body[data-theme-version="dark"] .bg-f1f2f5{
  background-color: #363062 !important;
}
body[data-theme-version="dark"] .follower__name{
  color: #828690 !important;
}
body[data-theme-version="dark"] .leader__name{
  color: #828690 !important;
}
body[data-theme-version="dark"] .bg-white.follower{
  background-color: #14112E !important;
}
.circle{
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 24px rgb(0 0 0 / 12%);
  border-radius: 50%;
  cursor: pointer;
}
.circle-svg{
  transform: rotate(90deg);
}
.btn{
  cursor: pointer;
}
.exc-price{
  border-bottom: 1px solid #d7dae3;
}
.font-size-28{
  font-size: 28px;
}
.color-black{
  color: #000;
}
.total-balance{
  color: #000;
  font-size: 28px;
  font-weight: 600;
}
.total-balance::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  background: linear-gradient(0deg,#fff,#efefef);
  border-radius: 13px 13px 0 0;
}
.adress__icon{
  cursor: pointer;
}
.text-big{
  font-weight: 600;
}
.balance-card{
  align-items: center !important;
}
.z-index-2{
  z-index: 2 !important;
}
.buy-icon{
  position: absolute;
  top: 10px;
  right: 10px;
}
.adress{
  border-radius: 8px;
  background: #7e7e7e;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
}
.fz-20{
  font-size: 20px;
}
.slick-dots{
  top: 100px !important;
  height: 50px !important;
}
.card-title{
  text-transform: initial;
}
.dropdown-curency{
  top: 5px;
  right: 3px;
}
.h-50-px{
  height: 50px !important;
}
.pr-10-px{
  padding-right: 10px;
}
.opacity-0{
  opacity: 0;
}
.arrow-svg{
  margin-top: -14px;
}
.tt-init{
  text-transform: initial !important;
}
.fz-22-px{
  font-size: 22px;
}
.p-7-px{
  padding: 7px !important;
}
.w-30-p{
  width: 30%;
  top: 5px;
}
.pointer{
  cursor: pointer;
}
.card-cash{
  width: 80%;
  margin: 0 auto;
}
@media screen and (max-width: 480px) {
  .card-cash{
    width: 100%;
    margin: 0 auto;
  }
  .card-cash .w-50{
    width: auto !important;
  }
  .verification-tittle{
    font-size: 16px;
  }
  .echange__middle{
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .balance__top .w-50{
    width: 100% !important;
  }
  .balance__top .font-size-28{
    display: none !important;
  }
  .w-30-p{
    width: 45%;
  }
  .circle-svg{
    transform: rotate(180deg);
  }
}
body[data-theme-version="dark"] .card-title{
  color: #fff;
}
body[data-theme-version="dark"] .total-balance::before{
  background: linear-gradient(349deg,#fff,#363062);
}
body[data-theme-version="dark"] .balance-numbers{
  color: #fff;
}
body[data-theme-version="dark"] .card-output{
  background-color: #05031A !important;
  border: 1px solid #2a2833;
}
.adress__symbols span{
  word-break: break-all;
}
.w-70-px{
  width: 70px;
}
.arrow-right{
  width: 20px;
  height: 20px;
  fill:#7e7e7e;
}
.card-link:hover .arrow-right{
  fill: #000;
}
.choice{
  top: 20px;
  left: 20px;
  z-index: 2;
}
.fz-28{
  font-size: 28px;
}
.fz-12{
  font-size: 12px;
}
body[data-theme-version="dark"] .card-link:hover .arrow-right{
  fill: #fff;
}
.circle--dollar{
  width: 42px;
  height: 42px;
  background-color: #7986cb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.btn-main-balance{
  padding: 0.375rem 0.75rem !important;
  margin-bottom: 4px !important;
}
.pointer .card{
  border: 1px solid transparent;
}
.currency--active{
  box-shadow: none !important;
  border: 1px solid #363062 !important;
}
.circle{
  transition: .4s;
}
.circle-transfotrm{
  transition: .4s;
  transform: rotate(360deg);
}
.close-filter{
  position: absolute;
  top: 1rem;
  right: 4px;
  z-index: 2;
  cursor: pointer;
}
.filter-input-group .react-datepicker{
  position: absolute !important;
  top: 100%;
  right: 0;
  z-index: 2;
}
@media screen and (max-width: 1400px) {
  .close-filter{
    top: 7px;
  }
}
@media screen and (max-width: 520px) {
  .filter-input-group{
    margin-bottom: 10px;
  }
  .close-filter{
    top: 9px;
  }
}
.ref-icon{
  fill: #000;
}
body[data-theme-version="dark"] .ref-icon{
  fill: #fff;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.usdr-icon img{
  width: 50% !important;
}
.get-cash-page .control-dots{
  top: 80px !important;
}
.get-cash-page .carousel-status{
 display: none !important;
}
.top-up-page .carousel-status{
 display: none !important;
}
.top-up-page .control-dots{
 height: 50px !important;
}
.get-cash-page .control-dots{
 height: 50px !important;
}
.avatar-pic{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-img{
  width: 100% !important;
}
.cirle-eth{
  background-color: #ccc;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-item{
  z-index: 12;
  position: relative;
}
.user-block .avatar-pic{
  width: 75px;
  height: 75px;
}
.custom-file-label::after{
  content: 'Загрузить' !important;
}
.file-input-done + .custom-file-label{
  border-color: green;
}
.file-input-done + .custom-file-label::after{
  background-color: green;
}
@media screen and (max-width: 520px) {
  .history-more-link{
    justify-content: flex-start !important;
  }
  .hisory-btns .btn{
    margin-bottom: 8px !important;
  }
}
.input-read-only{
  background-color: #f7f8f9 !important;
  cursor: default !important;
}
@media screen and (max-width: 520px) {
  .arrow-svg{
    margin-top: -5px;
  }
}
.no-wrap{
  flex-wrap: nowrap !important;
}
.ta-l{
  text-align: left !important;
}
body[data-theme-version="dark"] .input-read-only{
  color: #000 !important;
}
.output-balance{
  position: absolute;
  left: 10px;
  bottom: 5px;
  font-size: 10px;
}
.mt-minus{
  margin-top: -40px;
}
@media screen and (max-width: 480px) {
  .user-block{
    flex-direction: column;
  }
  .choice{
    display: none;
  }
}
.table-wrap{
  overflow-x: auto;
}
.avatar-img{
  width: 100% !important;
  height: auto !important;
}
@media screen and (max-width: 480px) {
  .label-header-btn::before{
    width: 30px !important;
    height: 14px !important;
  }
  .label-header-btn::after{
    width: 19px !important;
    height: 19px !important;
    top: -1px !important;
  }
  .toggle-switch{
    padding-top: 4px;
  }
  .history__list{
    overflow-x: auto !important;
  }
  .operation__right{
    padding-left: 15px;
    font-weight: 600;
  }
  .verify-img-wrap{
    margin-bottom: 20px;
  }
  .label-passport::after{
    height: 100% !important;
  }
}
.Toastify__toast--success{
  background-color: #21ba72 !important;
}
.label-passport::after{
  height: 100% !important;
}
.support-label::after{
  height: 100% !important;
}
.mh_210{
  min-height: 240px;
}
.crypto-walets{
  overflow-y: auto;
  height: 120px;
}

.crypto-walets::-webkit-scrollbar {
  width: 5px;
}
.crypto-walets::-webkit-scrollbar-track {
  background-color: transparent;
}
.crypto-walets::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: darkgrey;
}
.card-mobile{
  height: 240px !important;
}
@media screen and (max-width: 980px) {
  .mh_210{
    height: auto !important;
  }
  .card-mobile{
    height: 150px !important;
  }
}
.pr-4px{
  padding-right: 4px;
}
.crypto-walets{
  padding-right: 4px;
}
.dropdown-item .dropdown-item{
  padding: 0 !important;
}
.very-small{
  font-size: 12px;
  bottom: -8px;
  right: 0;
  font-size: 8px;
  width: 62px;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  background-color: #21ba72;
  border-radius: 2px;
  color: #fff;
}
.fz-18{
  font-size: 18px;
}
.delete-btn{
  color: #d2a30e;
}
.input-currency{
  right: 12px;
  top: 14px;
  color: #000;
}
.card-numbers{
  position: absolute;
  right: 3px;
  bottom: 5px;
  font-size: 10px;
}
/* .fz_14{
  font-size: 14px !important;
}
@media screen and(max-width: 1260px) {
  .fz_14{
    font-size: 12px !important;
  }
} */
.hisory-btns .btn-primary{
  background-color: transparent;
  color: #000;
  border: none;
  border: 1px solid transparent;
}
.hisory-btns .btn-primary:hover{
  border: 1px solid #969BA0;
  background-color: transparent;
  color: #000;
}
.hisory-btns .btn-primary:active{
  border: 1px solid transparent !important;
  background-color: transparent !important;
  color: #000 !important;
}
.hisory-btns .btn-primary.active{
  border: 1px solid #969BA0 !important;
  background-color: transparent !important;
  color: #000 !important;
}
.cash-title{
  top: 10px;
  right: 10px;
  position: absolute;
  z-index: 2;
}
body[data-theme-version="dark"] .hisory-btns .btn-primary{
  color: #fff;
}
body[data-theme-version="dark"] .hisory-btns .btn-primary.active{
  color: #fff !important;
}
body[data-theme-version="dark"] .sold-balance{
  color: #fff !important;
}
body[data-theme-version="dark"] .exc-price .text-primary{
  color: #fff !important;
}
.bottom_0{
  bottom: 0;
}
.balance-card__bottom{
  cursor: pointer;
}
.carousel-root .scale5{
  padding-right: 10px;
}
.arrow-back{
  stroke: #363062;
  transition: .2s;
}
.arrow-back:hover{
  stroke: #282348;
  transition: .2s;
}
.back-btn{
  cursor: pointer;
}
.back-btn--absolute{
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 30px;
}
.file-info{
  position: absolute;
}
.cards__item{
  position: relative;
  margin-bottom: 10px;
}
.cards__item:last-child{
  position: relative;
  margin-bottom: 0px;
}
.card-status{
  position: absolute;
  right: 50px;
}
.card-status{
  display: flex;
  align-items: center;
  top: 50%;
  margin-top: -16px;
  position: absolute;
}
@media screen and (max-width: 480px) {
  .back-btn--absolute{
    left: 10px;
  }
  .file-info{
    position: static;
  }
  .card-status{
    left: 0;
    bottom: 0;
    margin-top: 0;
  }
  .cards__item{
    padding-bottom: 30px;
  }
}

.status-text{
  margin-left: 10px;
  padding: 4px 6px;
  border-radius: 5px;
  background-color: #21ba72;
  color: #fff;
}
.color--blue{
  color: #007bff;
  cursor: pointer;
  line-height: 40px;
}
.color--blue:hover{
  color: #0056b3;
}
/* modal */
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  animation-name: appear;
  animation-duration: 300ms;
}

.modal-dialog {
  width: 100%;
  max-width: 550px;
  background: white;
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
}

.modal-header,
.modal-footer {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.modal-header {
  border-bottom: 1px solid #dbdbdb;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #dbdbdb;
  justify-content: flex-end;
}

.modal-close {
  font-size: 25px;
  cursor: pointer;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-body {
  overflow: auto;
}

.modal-content {
  padding: 1rem;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}
.border-none{
  border: none !important;
}